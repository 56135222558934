import React, { useState } from 'react'; // Importa React y hooks useState
import { collection, query, where, getDocs, addDoc } from 'firebase/firestore'; // Importa funciones de Firebase Firestore.
import { db } from '../api/firebase.config'; // Importa la configuración de Firebase.
import { getAuth } from 'firebase/auth'; // Importa la función para obtener el usuario autenticado.
import { useNavigate } from "react-router-dom"; // Hook para la navegación entre rutas en React
import Header from './../components/Header';
import Footer from './../components/Footer';

export const CanjeoCodigo = () => {

    // Define los estados locales del componente para almacenar la información del formulario.
    const [codigo, setCodigo] = useState('');
    const navigate = useNavigate();

    // Función para el conjeo de codigo.
    const Ccodigo = async (e) => {
        e.preventDefault();

        // Integramos que detecte si no a puesto un digito, nos mande una aleta 
        if (!codigo.trim()) {
            alert("Por favor, ingrese un código válido.");
            return;
        }

        try {

            const auth = getAuth();
            const usuarioAutenticado = auth.currentUser;

            // Llamamos a la funcion de verificacion si ya el usuario se canjeo el codigo.
            const yaCanjeado = await verificarCC(codigo, usuarioAutenticado.email);

            // Se confirma que ya uso ese codigo
            if (yaCanjeado) {

                alert("Este código ya ha sido canjeado por este usuario."); // Se muestra al usuario que ya uso ese codigo
                setCodigo(''); // Se vacia el campo donde se ingrea el codigo
                return;
            }

            const urlDestino = await obtenerUrlDeCodigo(codigo);
            if (!urlDestino) {
                alert("El código ingresado no es válido.");
                return;
            }

            // Se llama a la funcion de registro en la base de datos, para tener control de los canjeos de codigo
            await registrarCodigo(codigo, usuarioAutenticado.email);
            alert(`Código "${codigo}" canjeado con éxito!`); // Se muestra al usuario que se canjeo el codigo con éxito
            setCodigo(''); // Se vacia el campo donde se ingrea el codigo

            navigate(urlDestino);

        } catch (error) { // Muestra los errores
            console.error("Error al registrar el código:", error);
            alert("Hubo un error al canjear el código.");
        }
    };

    // Funcion de verificacion de codigo
    const verificarCC = async (codigo, email) => {
        try {

            const q = query( // Verifica si el codigo ya esta con la cuenta 
                collection(db, 'canjeoc'),
                where('usuario', '==', email),
                where('codigo', '==', codigo)
            );

            // Alerta de codigo si hay un error en en el proceso que se canjea el codigo
            const querySnapshot = await getDocs(q);
            return !querySnapshot.empty;
        } catch (error) {
            console.error('Error al verificar código canjeado:', error);
            return false;
        }
    };

    const obtenerUrlDeCodigo = async (codigo) => {
        try {
            const q = query(collection(db, "codigos"), where("codigo", "==", codigo));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                const docData = querySnapshot.docs[0].data();
                return docData.url;
            }

            return null;
        } catch (error) {
            console.error("Error al obtener la URL del código:", error);
            return null;
        }
    };

    // LA funcion que guarda los datos del usuario y codigo que se han canjeado en firebas
    const registrarCodigo = async (codigo, email) => {
        try {

            // Añade un nuevo documento en la colección 'canjeoc' en Firestore con la información del codigo y el usuario.
            await addDoc(collection(db, 'canjeoc'), {
                usuario: email,
                codigo: codigo
            });

        } catch (error) {
            console.error('Error al registrar el envío en Firebase:', error); // Maneja errores al registrar el envío.
            throw error;
        }
    };

    return (
        //Este es el pequeño formulario donde tenderemos que ingresar el codigo
        <div>
            <Header />
            <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 p-6">

                <div className="flex-grow bg-gray-100 py-10">
                    <div className="max-w-3xl mx-auto bg-white shadow-md rounded-lg p-8">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">
                            ¡Canjea tu código de promoción!
                        </h2>
                        <form>
                            <div className="mb-4">
                                <label htmlFor="codigo" className="block text-gray-700 font-medium mb-2">
                                    Código:
                                </label>
                                <input
                                    id="codigo" name="codigo" type="text" value={codigo} onChange={(e) => setCodigo(e.target.value)}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500" />
                            </div>
                            <div className="flex justify-center">
                                <button type="button" onClick={Ccodigo} className="bg-blue-500 text-white py-2 px-6 rounded-md hover:bg-blue-600 focus:outline-none transition-colors duration-300">
                                    Canjear Código
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default CanjeoCodigo;
