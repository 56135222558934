import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { UserAuth } from '../../context/AuthContext';


const MetadataForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {user} = UserAuth();
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    keywords: ''
  });
  const [errors, setErrors] = useState({});

  
useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch(`https://modelo-de-negocios-backend-180g.onrender.com/courses/metadata/${id}`);
      if (!response.ok) {
        throw new Error('Error al obtener datos');
      }
      const data = await response.json();
      console.log("Datos recibidos:", data);
      setFormData(data);
    } catch (error) {
      console.error(error);
    }
  };

  fetchData();
}, [id]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.title.trim()) {
      newErrors.title = 'El título es obligatorio';
    }
    if (!formData.description.trim()) {
      newErrors.description = 'La descripción es obligatoria';
    }
    if (!formData.keywords.trim()) {
      newErrors.keywords = 'Las palabras clave son obligatorias';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Retorna true si no hay errores
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return; // Si hay errores, no se envía el formulario
    }

    try {

      const metadata = {
        ...formData,
        editedBy: user.email,
        editorId: user.uid
      };

      const response = await fetch(`https://modelo-de-negocios-backend-180g.onrender.com/courses/metadata/${id}`, {
        method: 'PUT', // Cambiamos a PUT para actualizar
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(metadata)
      });

      if (!response.ok) {
        throw new Error('Error al enviar metadata');
      }

      const data = await response.json();
      console.log('Metadata enviado:', data);
      navigate(`/Administrator`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 p-4">
      <div className="max-w-lg w-full bg-white rounded-lg shadow-lg p-8">
        <h2 className="text-2xl font-bold mb-6 text-gray-800">Formulario de Metadata</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-gray-700">Título:</label>
            <input
              type="text"
              name="title"
              value={formData.title}
              onChange={handleChange}
              required
              className={`w-full px-4 py-2 border ${errors.title ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600`}
            />
            {errors.title && <p className="text-red-500 text-sm mt-1">{errors.title}</p>}
          </div>
          <div>
            <label className="block text-gray-700">Descripción:</label>
            <textarea
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
              className={`w-full px-4 py-2 border ${errors.description ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600`}
            />
            {errors.description && <p className="text-red-500 text-sm mt-1">{errors.description}</p>}
          </div>
          <div>
            <label className="block text-gray-700">Palabras clave:</label>
            <input
              type="text"
              name="keywords"
              value={formData.keywords}
              onChange={handleChange}
              required
              className={`w-full px-4 py-2 border ${errors.keywords ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-600`}
            />
            {errors.keywords && <p className="text-red-500 text-sm mt-1">{errors.keywords}</p>}
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-600"
          >
            Guardar Metadata
          </button>
        </form>
      </div>
    </div>
  );
};

export default MetadataForm;
