const loadStudentTestimonialsBlock = (editor) => {
    editor.BlockManager.add('Student Testimonials Block', {
      label: 'Student Testimonials Block',
      content: `
        <section class="student-testimonials" style="padding: 50px 20px; background: #fff;">
          <h2 style="text-align: center; font-size: 2em; margin-bottom: 30px;">What Our Students Say</h2>
          <div class="testimonials-container" style="display: flex; justify-content: space-around; gap: 20px; flex-wrap: wrap;">
            <div class="testimonial-item" style="flex: 1 1 30%; padding: 20px; box-shadow: 0 2px 5px rgba(0,0,0,0.1); min-width: 250px;">
              <p style="color: #777;">"This course was a game-changer for me. The content was well-structured and easy to follow."</p>
              <h4 style="font-size: 1.2em; margin-top: 15px;">- Jane Doe</h4>
            </div>
            <div class="testimonial-item" style="flex: 1 1 30%; padding: 20px; box-shadow: 0 2px 5px rgba(0,0,0,0.1); min-width: 250px;">
              <p style="color: #777;">"I highly recommend this course to anyone looking to deepen their understanding of the subject."</p>
              <h4 style="font-size: 1.2em; margin-top: 15px;">- John Smith</h4>
            </div>
            <div class="testimonial-item" style="flex: 1 1 30%; padding: 20px; box-shadow: 0 2px 5px rgba(0,0,0,0.1); min-width: 250px;">
              <p style="color: #777;">"The instructor was very knowledgeable and the course materials were top-notch."</p>
              <h4 style="font-size: 1.2em; margin-top: 15px;">- Emily Johnson</h4>
            </div>
          </div>
        </section>
        <style>
          @media (max-width: 768px) {
            .testimonials-container {
              flex-direction: column;
              align-items: center;
            }
            .testimonial-item {
              width: 100%;
              max-width: 400px;
            }
          }
        </style>
      `,
    });
  };
  
  export default loadStudentTestimonialsBlock;