
import loadBasicBlock from './BasicBlock';
import loadImageBlock from './ImageBlock';
import loadCodeBlock from './CodeBlock';
import loadCourseHeaderBlock from './Header';
import loadCourseDetailsBlock from './Details';
import loadCourseModulesBlock from './Modules';
import loadCourseContentBlock from './Content';
import loadStudentTestimonialsBlock from './Testimonials';





const loadBlogBlocks = (editor) => {
  loadCourseHeaderBlock(editor);
  loadCourseDetailsBlock(editor);
  loadCourseModulesBlock(editor);
  loadCourseContentBlock(editor);
  loadStudentTestimonialsBlock(editor);
  loadBasicBlock(editor);
  loadImageBlock(editor);
  loadCodeBlock(editor);
  
};

export default loadBlogBlocks;
