import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function LandingPageList() {
  const [landing, setLanding] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Estado para indicar si está cargando
  const [timeoutReached, setTimeoutReached] = useState(false); // Estado para manejar el tiempo de espera
  const [searchTerm, setSearchTerm] = useState('');  // Estado para el término de búsqueda



  useEffect(() => {
    const timeout = setTimeout(() => {
      setTimeoutReached(true);
    }, 50000); // 30 segundos de espera
  
    fetch(`https://modelo-de-negocios-backend-180g.onrender.com/courses`)
      .then(response => {
        clearTimeout(timeout); // Limpiar el timeout si la respuesta llega a tiempo
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setIsLoading(false); // Detener la carga cuando los datos son recibidos
        setLanding(data.documents); // Establecer los documentos recibidos
      })
      .catch(error => {
        clearTimeout(timeout);
        console.log("Error fetching landing pages: ", error);
        setIsLoading(false); // Detener la carga incluso en caso de error
      });
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("¿Estás seguro de eliminar este landing?");
    if (!confirmDelete) {
      return; // Cancelar la operación si el usuario no confirma
    }

    try {
      await fetch(`https://modelo-de-negocios-backend-180g.onrender.com/courseView/${id}`, {
        method: 'DELETE'
      });
      setLanding(prevLanding => prevLanding.filter(landingItem => landingItem._id !== id));
    } catch (error) {
      console.log(error.message);
    }
  };
  const filteredLanding = landing.filter(landingItem =>
    landingItem.title && landingItem.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <section>
      <div className="max-w-screen-xl mx-auto px-4 mt-8">
        <h1 className="text-3xl font-bold mb-4">Lista de cursos</h1>

        {/* Campo de entrada de búsqueda */}
        <input
          type="text"
          placeholder="Buscar curso..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="border p-2 rounded mb-4 w-full lg:w-1/3"
        />
        {/* Campo de entrada de búsqueda */}

        {isLoading && !timeoutReached && (
          <p className="text-xl text-center text-gray-600 m-3">Cargando cursos, por favor espera...</p>
        )}
        {!isLoading &&  filteredLanding.length === 0 && (
          <p className="text-xl text-center text-gray-600 m-10">No hay cursos disponibles.</p>
        )}
        <div className="overflow-x-auto">
          <table className="table-auto w-full border-collapse">
            <thead className="bg-gray-300">
              <tr>
                <th className="px-4 py-2">Fecha de creación</th>
                <th className="px-4 py-2">Título</th>
                <th className="px-4 py-2">Descripción</th>
                <th className="px-4 py-2">Editor</th>
                <th className="px-1 py-2">Eliminar</th>
                <th className="px-1 py-2">Editar</th>
                <th className="px-1 py-2">Ver</th>
              </tr>
            </thead>
            <tbody>
              { filteredLanding.map(landingItem => (
                <tr key={landingItem._id} className="border-t">
                  <td className="px-4 py-2 text-center">
                    {landingItem.createdAt 
                      ? new Date(landingItem.createdAt).toLocaleString("es-ES", {
                          day: "2-digit",
                          month: "2-digit",
                          year: "numeric"
                        })
                      : "Fecha no disponible"}
                  </td>
                  <td className="px-4 py-2">{landingItem.title || "Título no disponible"}</td>
                  <td className="px-4 py-2">{landingItem.description || "Descripción no disponible"}</td>
                  <td className="px-4 py-2">{landingItem.editedBy || 'Desconocido'}</td>
                  <td className="px-4 py-2">
                    <button
                      className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                      onClick={() => handleDelete(landingItem._id)}
                    >
                      Eliminar
                    </button>
                  </td>
                  <td className="px-4 py-2">
                    <Link
                      to={`/courseedit/${landingItem._id}`}  // Enlace para editar usando landingItem._id
                      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-2"
                    >
                      Editar
                    </Link>
                  </td>
                  <td className="px-4 py-2">
                    <Link
                      to={`/courseView/${landingItem.keywords ? landingItem.keywords.replace(/\s/g, '-') : landingItem._id}`}
                      className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-2"
                    >
                      Ver
                    </Link>
                  </td>
                </tr>
              ))}

            </tbody>
          </table>

        </div>
      </div>
      
    </section>
    
  );
}

export default LandingPageList;
