import React, { useState, useEffect } from "react";
import { db } from "../api/firebase.config";
import { collection, addDoc, getDocs, deleteDoc, doc, query, where } from "firebase/firestore";
import Navbar from '../components/Admin/NavBar'; // Componente de cabecera.

export const GenerarCodigo = () => {
    // Estados para almacenar la URL, el nombre del curso, el código generado y la lista de códigos
    const [url, setUrl] = useState("");
    const [nombreCurso, setNombreCurso] = useState("");
    const [codigoGenerado, setCodigoGenerado] = useState("");
    const [codigos, setCodigos] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    // Generar código aleatorio
    const generarCodigoAleatorio = () => Math.random().toString(36).substr(2, 8).toUpperCase();

    // Obtener los códigos de Firestore
    const obtenerCodigos = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, "codigos"));
            const listaCodigos = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
            setCodigos(listaCodigos.sort((a, b) => a.url.localeCompare(b.url))); // Ordenar por URL
        } catch (error) {
            console.error("Error al obtener códigos:", error);
        }
    };

    // Guardar código en Firestore
    const guardarCodigoEnFirestore = async () => {
        if (!url.trim() || !nombreCurso.trim()) {
            alert("Por favor, ingrese una URL y un Nombre de Curso válidos.");
            return;
        }

        const nuevoCodigo = generarCodigoAleatorio();

        try {
            // Agrega un nuevo documento a la colección "codigos" en Firestore
            await addDoc(collection(db, "codigos"), {
                codigo: nuevoCodigo, // Guardar el codigo generado 
                url,  // Guardar el url curso
                nombreCurso // Guardar también el nombre del curso
            });
            setCodigoGenerado(nuevoCodigo);// Almacena el código generado en el estado
            setUrl(""); // Limpia el campo URL
            setNombreCurso(""); // Limpiar el input del nombre del curso
            alert(`Código generado con éxito: ${nuevoCodigo}`);
            obtenerCodigos(); // Actualiza la lista de códigos
        } catch (error) {
            console.error("Error al guardar el código:", error);
            alert("Hubo un error al generar el código.");
        }
    };

    // Eliminar código de ambas colecciones
    const eliminarCodigo = async (id) => {
        try {
            // Encuentra el código correspondiente en la lista
            const codigoDoc = codigos.find((codigo) => codigo.id === id);
            if (!codigoDoc) return;

            const codigoAEliminar = codigoDoc.codigo;
            // Elimina el código de la colección "codigos"
            await deleteDoc(doc(db, "codigos", id));
            console.log(`Código eliminado de 'codigos': ${codigoAEliminar}`);
            // Busca el código en la colección "canjeoc" y lo elimina si existe
            const canjeocQuery = query(collection(db, "canjeoc"), where("codigo", "==", codigoAEliminar));
            const canjeocSnapshot = await getDocs(canjeocQuery);

            canjeocSnapshot.forEach(async (doc) => {
                await deleteDoc(doc.ref);
                console.log(`Código eliminado de 'canjeoc': ${codigoAEliminar}`);
            });

            alert("Código eliminado de ambas colecciones.");
            obtenerCodigos(); // Actualiza la lista de códigos
        } catch (error) {
            console.error("Error al eliminar el código:", error);
            alert("No se pudo eliminar el código.");
        }
    };

    // Cambiar de página
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Cargar códigos al montar el componente
    useEffect(() => {
        obtenerCodigos();
    }, []);

    // Paginación
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = codigos.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 p-6">
            <Navbar /> {/* Componente de cabecera */}
            <div className="max-w-3xl mx-auto bg-white shadow-md rounded-lg p-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">
                    Generar Código de Acceso
                </h2>
                <div className="mb-4">
                    {/* Input URL */}
                    <label htmlFor="url" className="block text-gray-700 font-medium mb-2">
                        URL del curso:
                    </label>
                    <input
                        id="url" type="text" value={url} onChange={(e) => setUrl(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Ejemplo: /pagina-secreta" />

                    {/* Input Nombre del Curso */}
                    <label htmlFor="nombreCurso" className="block text-gray-700 font-medium mb-2 mt-4">
                        Nombre del curso:
                    </label>
                    <input
                        id="nombreCurso" type="text" value={nombreCurso} onChange={(e) => setNombreCurso(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        placeholder="Ejemplo: Curso de React Avanzado" />
                </div>
                <div className="flex justify-center">
                    <button
                        onClick={guardarCodigoEnFirestore}
                        className="bg-blue-500 text-white py-2 px-6 rounded-md hover:bg-blue-600 transition-colors duration-300">
                        Generar Código
                    </button>
                </div>
                {codigoGenerado && (
                    <div className="mt-4 p-4 bg-green-100 border border-green-500 rounded-lg">
                        <p className="text-green-700 font-semibold">Código generado:</p>
                        <p className="text-lg font-bold">{codigoGenerado}</p>
                    </div>
                )}
                {/* Tabla de códigos */}
                <div className="overflow-hidden bg-white shadow-md rounded-lg mt-6">
                    <table className="w-full border-collapse">
                        <thead className="bg-blue-500 text-white">
                            <tr>
                                <th className="px-4 py-2">Nombre del Curso</th>
                                <th className="px-4 py-2">Código</th>
                                <th className="px-4 py-2">URL</th>
                                <th className="px-4 py-2">Acción</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((codigo) => (
                                <tr key={codigo.id} className="odd:bg-gray-100 even:bg-white hover:bg-gray-200 transition">
                                    <td className="px-4 py-2 text-center">{codigo.nombreCurso}</td>
                                    <td className="px-4 py-2 text-center">{codigo.codigo}</td>
                                    <td className="px-4 py-2 text-center">{codigo.url}</td>
                                    <td className="px-4 py-2 text-center">
                                        <button
                                            onClick={() => eliminarCodigo(codigo.id)}
                                            className="bg-red-500 text-white px-3 py-1 rounded-md hover:bg-red-600 transition">
                                            Eliminar
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {/* Paginación */}
                <div className="flex justify-center mt-4">
                    {Array.from({ length: Math.ceil(codigos.length / itemsPerPage) }, (_, index) => (
                        <button
                            key={index + 1}
                            onClick={() => paginate(index + 1)}
                            className={`mx-1 px-3 py-1 border rounded-md ${currentPage === index + 1 ? "bg-blue-500 text-white" : "bg-gray-200"
                                }`}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default GenerarCodigo;
