const loadCourseContentBlock = (editor) => {
  editor.BlockManager.add("Course Content Block", {
    label: "Course Content Block",
    content: `
        <section class="course-content" style="padding: 80px 20px; background: linear-gradient(135deg, #f9f9f9, #eef2f6);">
          <div class="content-container" style="max-width: 1200px; margin: 0 auto; background: #fff; border-radius: 15px; box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1); padding: 40px;">
            <!-- Título del Contenido -->
            <h2 style="font-size: 2.5em; margin-bottom: 20px; color: #333; font-weight: 700; text-align: center;">Module Title</h2>
  
            <!-- Subtítulo del Contenido -->
            <h3 style="font-size: 1.8em; margin-bottom: 25px; color: #555; font-weight: 600; text-align: center;">Subtitle or Section Title</h3>
  
            <!-- Imagen del Contenido (Grande y Centrada) -->
            <div style="display: flex; justify-content: center; align-items: center; width: 100%; margin-bottom: 40px;">
              <img src="https://via.placeholder.com/1200x600" alt="Course Image" style="width: 50%; max-width: 50%; height: auto; border-radius: 10px; box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);">
            </div>
  
            <!-- Contenido Textual -->
            <div style="font-size: 1.1em; line-height: 1.8; color: #444; margin-bottom: 30px;">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam in venenatis enim. Sed euismod, nisl nec aliquet aliquam, nunc nisl aliquet nunc, nec aliquet nisl nunc nec nisl. Sed euismod, nisl nec aliquet aliquam, nunc nisl aliquet nunc, nec aliquet nisl nunc nec nisl.</p>
              <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Donec euismod, nisl nec aliquet aliquam, nunc nisl aliquet nunc, nec aliquet nisl nunc nec nisl.</p>
            </div>
  
            <!-- Lista de Puntos Clave -->
            <ul style="margin-top: 20px; padding-left: 20px; list-style-type: none; color: #444;">
              <li style="margin-bottom: 15px; padding-left: 20px; position: relative;">
                <span style="position: absolute; left: 0; top: 50%; transform: translateY(-50%); width: 10px; height: 10px; background: #007bff; border-radius: 50%;"></span>
                Key Point 1: Lorem ipsum dolor sit amet.
              </li>
              <li style="margin-bottom: 15px; padding-left: 20px; position: relative;">
                <span style="position: absolute; left: 0; top: 50%; transform: translateY(-50%); width: 10px; height: 10px; background: #007bff; border-radius: 50%;"></span>
                Key Point 2: Consectetur adipiscing elit.
              </li>
              <li style="margin-bottom: 15px; padding-left: 20px; position: relative;">
                <span style="position: absolute; left: 0; top: 50%; transform: translateY(-50%); width: 10px; height: 10px; background: #007bff; border-radius: 50%;"></span>
                Key Point 3: Nullam in venenatis enim.
              </li>
            </ul>
  
            <!-- Botón de Acción -->
            <div style="text-align: center; margin-top: 40px;">
              <a href="#" style="background: linear-gradient(135deg, #007bff, #0056b3); color: #fff; padding: 15px 40px; text-decoration: none; font-size: 1.1em; border-radius: 50px; display: inline-block; transition: transform 0.3s ease, box-shadow 0.3s ease; box-shadow: 0 5px 15px rgba(0, 123, 255, 0.3);">
                Download Resources
              </a>
            </div>
          </div>
        </section>
        <style>
          /* Efecto hover para el botón */
          .course-content a:hover {
            transform: translateY(-3px);
            box-shadow: 0 8px 20px rgba(0, 123, 255, 0.4);
          }
  
          /* Estilos Responsivos */
          @media (max-width: 768px) {
            .course-content {
              padding: 40px 15px;
            }
            .course-content h2 {
              font-size: 2em;
            }
            .course-content h3 {
              font-size: 1.5em;
            }
            .course-content p {
              font-size: 1em;
            }
            .course-content ul {
              font-size: 1em;
            }
            .course-content a {
              font-size: 1em;
              padding: 12px 30px;
            }
          }
  
          @media (max-width: 480px) {
            .course-content h2 {
              font-size: 1.8em;
            }
            .course-content h3 {
              font-size: 1.3em;
            }
            .course-content img {
              border-radius: 8px;
            }
            .course-content a {
              width: 100%;
              padding: 12px 20px;
            }
          }
        </style>
      `,
  });
};

export default loadCourseContentBlock;
