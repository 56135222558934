const loadCourseDetailsBlock = (editor) => {
    editor.BlockManager.add('Course Details Block', {
      label: 'Course Details Block',
      content: `
        <section class="course-details" style="padding: 50px 20px; background: #fff;">
          <div style="display: flex; justify-content: space-around; gap: 20px; flex-wrap: wrap;">
            <div class="detail-item" style="flex: 1 1 30%; text-align: center; padding: 20px; box-shadow: 0 2px 5px rgba(0,0,0,0.1); min-width: 200px;">
              <h4 style="font-size: 1.5em;">Duration</h4>
              <p style="color: #777;">10 Weeks</p>
            </div>
            <div class="detail-item" style="flex: 1 1 30%; text-align: center; padding: 20px; box-shadow: 0 2px 5px rgba(0,0,0,0.1); min-width: 200px;">
              <h4 style="font-size: 1.5em;">Level</h4>
              <p style="color: #777;">Intermediate</p>
            </div>
            <div class="detail-item" style="flex: 1 1 30%; text-align: center; padding: 20px; box-shadow: 0 2px 5px rgba(0,0,0,0.1); min-width: 200px;">
              <h4 style="font-size: 1.5em;">Instructor</h4>
              <p style="color: #777;">John Doe</p>
            </div>
          </div>
        </section>
        <style>
          @media (max-width: 768px) {
            .course-details {
              flex-direction: column;
              align-items: center;
            }
            .detail-item {
              width: 100%;
              max-width: 400px;
            }
          }
        </style>
      `,
    });
  };
  
  export default loadCourseDetailsBlock;