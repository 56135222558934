import React, { useState, useEffect } from "react"; // Importa React y hooks useState y useEffect
import { db } from "../api/firebase.config"; // Importa la configuración de Firebase Firestore
import { collection, query, where, getDocs } from "firebase/firestore"; // Importa funciones para consultar Firestore
import { getAuth, onAuthStateChanged } from "firebase/auth"; // Importa funciones de autenticación de Firebase
import { useNavigate } from "react-router-dom"; // Hook para la navegación entre rutas en React
import Header from './../components/Header';
import Footer from './../components/Footer';

const UsuarioCodi = () => {
    const [codigos, setCodigos] = useState([]); // Estado para almacenar los códigos canjeados
    const [loading, setLoading] = useState(true); // Estado para manejar la carga de datos
    const [user, setUser] = useState(null); // Estado para almacenar el usuario autenticado
    const auth = getAuth(); // Obtiene la instancia de autenticación de Firebase
    const navigate = useNavigate(); // Hook para redirigir al usuario a otra página

    // useEffect se ejecuta al montar el componente y se suscribe a los cambios en la autenticación del usuario
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (usuario) => {
            if (usuario) {
                setUser(usuario); // Guarda el usuario autenticado
                obtenerCodigosCanjeados(usuario.email); // Obtiene los códigos canjeados por el usuario
            } else {
                setLoading(false); // Si no hay usuario, deja de cargar
            }
        });

        return () => unsubscribe(); // Se desuscribe del evento cuando el componente se desmonta
    }, [auth]);

    // Función para obtener los códigos canjeados por el usuario desde Firestore
    const obtenerCodigosCanjeados = async (emailUsuario) => {
        try {
            // Crea una consulta para obtener los documentos de la colección "canjeoc" donde el usuario coincide con el email actual
            const q = query(collection(db, "canjeoc"), where("usuario", "==", emailUsuario));
            const querySnapshot = await getDocs(q);

            // Mapea los documentos obtenidos y extrae la información de cada código
            const listaCodigos = await Promise.all(querySnapshot.docs.map(async (doc) => {
                const data = doc.data();
                const datosCodigo = await obtenerDatosDeCodigo(data.codigo); // Obtiene detalles adicionales del código
                return {
                    id: doc.id,
                    codigo: data.codigo,
                    nombreCurso: datosCodigo?.nombreCurso || "Curso Desconocido", // Si no hay nombre, se muestra un valor por defecto
                    url: datosCodigo?.url || null, // Si no hay URL, se deja en null
                };
            }));

            setCodigos(listaCodigos); // Guarda la lista de códigos en el estado
        } catch (error) {
            console.error("Error al obtener códigos canjeados:", error);
        } finally {
            setLoading(false); // Detiene la carga después de obtener los datos
        }
    };

    // Función para obtener información adicional de un código desde la colección "codigos"
    const obtenerDatosDeCodigo = async (codigo) => {
        try {
            // Busca el documento en la colección "codigos" donde el campo "codigo" coincida con el valor recibido
            const q = query(collection(db, "codigos"), where("codigo", "==", codigo));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                return querySnapshot.docs[0].data(); // Retorna la información del primer documento encontrado
            }
        } catch (error) {
            console.error("Error al obtener la información del código:", error);
        }
        return null; // Si hay un error o no se encuentra información, retorna null
    };

    // Función para manejar el clic en un curso y redirigir al usuario a su URL
    const manejarClickCodigo = (url) => {
        if (url) {
            navigate(url); // Redirige a la URL del curso
        } else {
            alert("No se encontró la URL para este curso."); // Muestra una alerta si no hay URL
        }
    };

    // Muestra un mensaje de carga mientras se obtienen los datos
    if (loading) return <p className="text-center mt-10">Cargando...</p>;

    return (
        <div>
            <Header />
            <div className="min-h-screen flex flex-col items-center bg-gray-100 p-6">

                <div className="max-w-3xl w-full bg-white shadow-md rounded-lg p-8">
                    <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">
                        Tus cursos
                    </h2>
                    {/* Si el usuario no tiene cursos canjeados, muestra un mensaje */}
                    {codigos.length === 0 ? (
                        <p className="text-center text-gray-600">No has canjeado ningún curso aún.</p>
                    ) : (
                        <ul className="space-y-4">
                            {/* Lista de cursos canjeados con opción de clic */}
                            {codigos.map((curso) => (
                                <li
                                    key={curso.id} className="p-4 bg-blue-100 rounded-lg shadow cursor-pointer hover:bg-blue-200 transition"
                                    onClick={() => manejarClickCodigo(curso.url)}>
                                    <p className="text-lg font-semibold">Curso: {curso.nombreCurso}</p>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>

            </div>
            <Footer />
        </div>
    );
};

export default UsuarioCodi; // Exporta el componente para su uso en otras partes de la aplicación

