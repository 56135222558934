const loadCourseHeaderBlock = (editor) => {
    editor.BlockManager.add('Course Header Block', {
      label: 'Course Header Block',
      content: `
        <section class="course-header" style="padding: 60px 20px; background: #f9f9f9; text-align: center;">
          <h1 style="font-size: 2.5em; margin-bottom: 20px;">Course Title</h1>
          <p style="font-size: 1.2em; color: #555;">A brief description of the course goes here. This can be a summary of what students will learn.</p>
        </section>
        <style>
          @media (max-width: 768px) {
            .course-header h1 {
              font-size: 2em;
            }
            .course-header p {
              font-size: 1em;
            }
          }
        </style>
      `,
    });
  };
  
  export default loadCourseHeaderBlock;