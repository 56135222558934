const loadCourseModulesBlock = (editor) => {
    editor.BlockManager.add('Course Modules Block', {
      label: 'Course Modules Block',
      content: `
        <section class="course-modules" style="padding: 50px 20px; background: #f9f9f9;">
          <h2 style="text-align: center; font-size: 2em; margin-bottom: 30px;">Course Modules</h2>
          <div class="modules-container" style="display: flex; flex-direction: column; gap: 20px;">
            <div class="module-item" style="background: #fff; padding: 20px; box-shadow: 0 2px 5px rgba(0,0,0,0.1);">
              <h3 style="font-size: 1.5em;">Module 1: Introduction</h3>
              <p style="color: #777;">An overview of the course and what to expect.</p>
            </div>
            <div class="module-item" style="background: #fff; padding: 20px; box-shadow: 0 2px 5px rgba(0,0,0,0.1);">
              <h3 style="font-size: 1.5em;">Module 2: Core Concepts</h3>
              <p style="color: #777;">Dive into the fundamental concepts of the course.</p>
            </div>
            <div class="module-item" style="background: #fff; padding: 20px; box-shadow: 0 2px 5px rgba(0,0,0,0.1);">
              <h3 style="font-size: 1.5em;">Module 3: Advanced Topics</h3>
              <p style="color: #777;">Explore advanced topics and real-world applications.</p>
            </div>
          </div>
        </section>
        <style>
          @media (max-width: 768px) {
            .course-modules h2 {
              font-size: 1.8em;
            }
            .module-item h3 {
              font-size: 1.3em;
            }
            .module-item p {
              font-size: 1em;
            }
          }
        </style>
      `,
    });
  };
  
  export default loadCourseModulesBlock;