import React, { useState } from "react";
import { Link } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { FiMenu, FiLogOut } from "react-icons/fi";
import { MdKeyboardArrowDown } from "react-icons/md";
import { AiOutlineHome } from "react-icons/ai";
import { IoIosSchool } from "react-icons/io";


function Navbar() {
  const { user, logOut } = UserAuth();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isContentDropdownOpen, setIsContentDropdownOpen] = useState(false);
  const [isEmailDropdownOpen, setIsEmailDropdownOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);


  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleContentDropdown = () =>
    setIsContentDropdownOpen(!isContentDropdownOpen);
  const toggleEmailDropdown = () =>
    setIsEmailDropdownOpen(!isEmailDropdownOpen);
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);


  const cerrarSesión = async () => {
    try {
      await logOut();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <header className="bg-white shadow-md fixed top-0 w-full z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-16">
          {/* Logo */}
          <div className="flex-shrink-0">
            <Link to="/" className="text-2xl font-bold text-blue-600">
              Negocios Digitales
            </Link>
          </div>

          {/* Desktop Links */}
          <nav className="hidden md:flex space-x-6 items-center">
            <Link
              to="/"
              className="flex items-center text-gray-600 hover:text-blue-600"
            >
              <AiOutlineHome className="mr-2" /> Inicio
            </Link>

            <Link
              to="/coursecreator"
              className="flex items-center text-gray-600 hover:text-blue-600"
            >
              <IoIosSchool className="mr-2" /> Subir Curso
            </Link>

            <Link
              to="/generac"
              className="flex items-center text-gray-600 hover:text-blue-600"
            >
              <IoIosSchool className="mr-2" /> Generar codigo
            </Link>

            {/* Subir Contenido Dropdown */}
            <div className="relative">
              <button
                onClick={toggleContentDropdown}
                className="flex items-center text-gray-600 hover:text-blue-600 transition"
              >
                Subir Contenido{" "}
                <MdKeyboardArrowDown
                  className={`ml-1 ${
                    isContentDropdownOpen ? "rotate-180" : ""
                  }`}
                />
              </button>
              {isContentDropdownOpen && (
                <div className="absolute mt-2 bg-white border rounded-lg shadow-lg w-48">
                  <Link
                    to="/blogcreator"
                    className="block px-4 py-2 text-sm text-gray-600 hover:bg-gray-100"
                  >
                    Blog
                  </Link>
                  <Link
                    to="/newslettercreator"
                    className="block px-4 py-2 text-sm text-gray-600 hover:bg-gray-100"
                  >
                    Newsletter
                  </Link>
                  <Link
                    to="/landingpagecreator"
                    className="block px-4 py-2 text-sm text-gray-600 hover:bg-gray-100"
                  >
                    Landing Pages
                  </Link>
                </div>
              )}
            </div>

            {/* Email y Contactos Dropdown */}
            <div className="relative">
              <button
                onClick={toggleEmailDropdown}
                className="flex items-center text-gray-600 hover:text-blue-600 transition"
              >
                Gestión Emails{" "}
                <MdKeyboardArrowDown
                  className={`ml-1 ${isEmailDropdownOpen ? "rotate-180" : ""}`}
                />
              </button>
              {isEmailDropdownOpen && (
                <div className="absolute mt-2 bg-white border rounded-lg shadow-lg w-48">
                  <Link
                    to="/e-main"
                    className="block px-4 py-2 text-sm text-gray-600 hover:bg-gray-100"
                  >
                    Enviar Email
                  </Link>
                  <Link
                    to="/regisenvi"
                    className="block px-4 py-2 text-sm text-gray-600 hover:bg-gray-100"
                  >
                    Registrar Email
                  </Link>
                  <Link
                    to="/crm"
                    className="block px-4 py-2 text-sm text-gray-600 hover:bg-gray-100"
                  >
                    Contactos
                  </Link>
                </div>
              )}
            </div>



            {/* Configuraciones Dropdown */}
            <div className="relative">
              <button
                onClick={toggleDropdown}
                className="flex items-center text-gray-600 hover:text-blue-600 transition"
              >
                Configuraciones
                <MdKeyboardArrowDown
                  className={`ml-1 ${isDropdownOpen ? "rotate-180" : ""}`}
                />
              </button>
              {isDropdownOpen && (
                <div className="absolute mt-2 bg-white border rounded-lg shadow-lg w-48">
                  <Link
                    to="/indexedit"
                    className="block px-4 py-2 text-sm text-gray-600 hover:bg-gray-100"
                  >
                    Editar Índice
                  </Link>
                  <Link
                    to="/indexmeta"
                    className="block px-4 py-2 text-sm text-gray-600 hover:bg-gray-100"
                  >
                    Editar SEO
                  </Link>
                </div>
              )}
            </div>

            <button
              onClick={cerrarSesión}
              className="flex items-center text-gray-600 hover:text-red-600 transition"
            >
              <FiLogOut className="mr-2" /> Cerrar Sesión
            </button>
          </nav>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button
              onClick={toggleMenu}
              className="text-gray-600 hover:text-blue-600 transition"
            >
              <FiMenu size={24} />
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div className="md:hidden bg-white shadow-lg">
          <nav className="px-4 py-2 space-y-2">
            <Link to="/" className="block text-gray-600 hover:text-blue-600">
              Inicio
            </Link>
            <button
              onClick={toggleContentDropdown}
              className="flex items-center justify-between w-full text-gray-600 hover:text-blue-600"
            >
              Subir Contenido
              <MdKeyboardArrowDown
                className={`transform ${
                  isContentDropdownOpen ? "rotate-180" : ""
                }`}
              />
            </button>
            {isContentDropdownOpen && (
              <div className="pl-4">
                <Link
                  to="/blogcreator"
                  className="block text-sm text-gray-600 hover:text-blue-600"
                >
                  Blog
                </Link>
                <Link
                  to="/newslettercreator"
                  className="block text-sm text-gray-600 hover:text-blue-600"
                >
                  Newsletter
                </Link>
                <Link
                  to="/landingpagecreator"
                  className="block text-sm text-gray-600 hover:text-blue-600"
                >
                  Landing Pages
                </Link>
              </div>
            )}
            <button
              onClick={toggleEmailDropdown}
              className="flex items-center justify-between w-full text-gray-600 hover:text-blue-600"
            >
              Gestión Emails
              <MdKeyboardArrowDown
                className={`transform ${
                  isEmailDropdownOpen ? "rotate-180" : ""
                }`}
              />
            </button>
            {isEmailDropdownOpen && (
              <div className="pl-4">
                <Link
                  to="/e-main"
                  className="block text-sm text-gray-600 hover:text-blue-600"
                >
                  Enviar Email
                </Link>
                <Link
                  to="/regisenvi"
                  className="block text-sm text-gray-600 hover:text-blue-600"
                >
                  Registrar Email
                </Link>
                <Link
                  to="/crm"
                  className="block text-sm text-gray-600 hover:text-blue-600"
                >
                  Contactos
                </Link>
              </div>
            )}
            <Link
              to="/configuraciones"
              className="block text-gray-600 hover:text-blue-600"
            >
              Configuraciones
            </Link>
            <button
              onClick={cerrarSesión}
              className="block text-gray-600 hover:text-red-600"
            >
              Cerrar Sesión
            </button>
          </nav>
        </div>
      )}
    </header>
  );
}

export default Navbar;
